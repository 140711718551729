var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"item-key":"siloPropertyId","headers":_vm.headers,"items":_vm.properties,"items-per-page":18},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"overline"},[_vm._v(_vm._s(item.name))])]}},{key:`item.globalSilo`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":item.globalSilo ? 'green' : 'orange'}},[_vm._v(_vm._s(item.globalSilo ? "mdi-check" : "mdi-cancel"))])]}},{key:`item.type`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.type == 0 ? "mdi-cylinder" : "mdi-cube"))]),_vm._v(" "+_vm._s(item.type == 0 ? _vm.$t("silos.cylindrical") : _vm.$t("silos.cube"))+" ")]}},{key:`item.totalVolumeM`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.totalVolumeM)+" m³ ")]}},{key:`item.actions`,fn:function({ item }){return [(
          _vm.permitted('SiloProperties.Update') && item.companyId == _vm.companyId
        )?_c('v-btn',{attrs:{"icon":""},on:{"click":() => _vm.$router.push(`/properties/${item.siloPropertyId}`)}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pen")])],1):_vm._e(),(
          _vm.permitted('SiloProperties.Delete') && item.companyId == _vm.companyId
        )?_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
            _vm.removeDialog = true;
            _vm.selectedId = item.siloPropertyId;
          }}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)}),_c('delete-dialog',{attrs:{"dialog":_vm.removeDialog},on:{"cancel-dialog":function($event){_vm.removeDialog = false},"confirm-delete":function($event){return _vm.remove(_vm.selectedId)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }