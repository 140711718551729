<template>
  <v-container fluid>
    <v-data-table
      item-key="siloPropertyId"
      :headers="headers"
      :items="properties"
      :items-per-page="18"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span class="overline">{{ item.name }}</span>
      </template>

      <template v-slot:[`item.globalSilo`]="{ item }">
        <v-icon :color="item.globalSilo ? 'green' : 'orange'">{{
          item.globalSilo ? "mdi-check" : "mdi-cancel"
        }}</v-icon>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-icon color="primary">{{
          item.type == 0 ? "mdi-cylinder" : "mdi-cube"
        }}</v-icon>

        {{ item.type == 0 ? $t("silos.cylindrical") : $t("silos.cube") }}
      </template>

      <template v-slot:[`item.totalVolumeM`]="{ item }">
        {{ item.totalVolumeM }} m³
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="
            permitted('SiloProperties.Update') && item.companyId == companyId
          "
          icon
          @click="() => $router.push(`/properties/${item.siloPropertyId}`)"
        >
          <v-icon color="primary">mdi-pen</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="
            () => {
              removeDialog = true;
              selectedId = item.siloPropertyId;
            }
          "
          v-if="
            permitted('SiloProperties.Delete') && item.companyId == companyId
          "
        >
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <delete-dialog
      :dialog="removeDialog"
      v-on:cancel-dialog="removeDialog = false"
      v-on:confirm-delete="remove(selectedId)"
    />
  </v-container>
</template>

<script>
import DeleteDialog from "../common/DeleteDialog.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SiloPropertyList",

  components: {
    DeleteDialog,
  },

  data() {
    return {
      siloPropertyModel: false,
      siloPropertySelected: {},
      removeDialog: false,
      selectedId: null,
      headers: [
        { text: this.$t("silos.siloProperty.fields.name"), value: "name" },
        { text: this.$t("silos.siloProperty.fields.type"), value: "type" },
        {
          text: this.$t("silos.siloProperty.fields.globalSilo"),
          value: "globalSilo",
        },
        {
          text: this.$t("silos.siloProperty.fields.totalVolumeM"),
          value: "totalVolumeM",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapState("silos", ["properties"]),
    ...mapGetters("users", ["companyId"]),
  },

  methods: {
    ...mapActions("silos", ["getSiloProperties", "deleteProperty"]),

    async remove(id) {
      if (id == null) return;

      await this.deleteProperty(id);
      this.removeDialog = false;
      this.selectedId = null;
    },
  },

  async created() {
    await this.getSiloProperties();
  },
};
</script>